// nav_mobile_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

    connect() {
        // TODO Convert to vanilla JS and CSS so we can drop jQuery dependency
        $(".nav-main-mobile").stop().click(function() {
            $('#mobile-nav').slideToggle();
        });

        $(".mobile-nav_secondary-login").stop().click(function(e) {
            e.preventDefault();
            $('.mobile-nav_secondary-login-options').slideToggle();
        });
    }
}
