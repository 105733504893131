import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
import * as Turbo from "@hotwired/turbo"

window.Stimulus = Application.start()
const context = require.context("./controllers", true, /\.js$/)
Stimulus.load(definitionsFromContext(context))

Turbo.start();

// Workaround for Firefox not scrolling to top on page load when smooth scrolling is set
// https://github.com/hotwired/turbo/issues/426
if (navigator.userAgent.match(/firefox|fxios/i)) {
    document.addEventListener(`turbo:load`, () => {
        document.documentElement.style.scrollBehavior = `smooth`;
    });

    document.addEventListener(`turbo:before-visit`, () => {
        document.documentElement.style.scrollBehavior = `unset`;
    });
}
