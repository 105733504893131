// nav_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

    connect() {
        let body = document.body;

        if (body.classList.contains("page-category--members")) {
            this.element.querySelector(".nav-category--members").classList.add('active');
        }
        if (body.classList.contains("page-category--dentists")) {
            this.element.querySelector(".nav-category--dentists").classList.add('active');
        }
        if (body.classList.contains("page-category--brokers")) {
            this.element.querySelector(".nav-category--brokers").classList.add('active');
        }
        if (body.classList.contains("page-category--employers")) {
            this.element.querySelector(".nav-category--employers").classList.add('active');
        }
    }
}
