// carousel_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

    connect() {
        // TODO Vanilla JS carousel so we can drop jQuery dependency
        // Oklahoma Facts Carousel
        $(this.element).slick({
            autoplay: true,
            arrows: false,
            dots: true,
        });
    }
}
