// faq_set_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

    static targets = ["tab", "tabContent", "overview"];

    connect() {
    }

    switchVersion(event) {
        const versionId = event.params.versionId;

        if (this.hasTabTarget) {
            for (let tab of this.tabTargets) {
                tab.classList.remove("active");
            }
        }
        event.target.parentNode.classList.add("active");

        for (let tabContent of this.tabContentTargets) {
            if (tabContent.id === `tab-${versionId}`) {
                tabContent.classList.add("tw-block");
                tabContent.classList.remove("tw-hidden");
            } else {
                tabContent.classList.add("tw-hidden");
                tabContent.classList.remove("tw-block");
            }
        }
        for (let overview of this.overviewTargets) {
            if (overview.id === `overview-${versionId}`) {
                overview.classList.add("tw-block");
                overview.classList.remove("tw-hidden");
            } else {
                overview.classList.add("tw-hidden");
                overview.classList.remove("tw-block");
            }
        }
    }
}
