// home_controller.js
import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from 'stimulus-use';

export default class extends Controller {

    static targets = ["dropdown", "dropdownSelect"];

    toggleMenu(event){
        this.dropdownTarget.classList.toggle("open");
        this.dropdownSelectTarget.classList.toggle("open");
    }

    closeMenu(event) {
        this.dropdownTarget.classList.remove("open");
        this.dropdownSelectTarget.classList.remove("open");
    }

    clickOutside(event) {
        this.closeMenu(event);
    }

    connect() {
        useClickOutside(this);
    }
}
