// faq_set_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

    static values = {
        id: Number
    }

    static targets = ["container"]

    connect() {}

    toggle(event) {
        if (event.detail.id && event.detail.id === this.idValue) {
            this.containerTarget.classList.add("faq-question--open");
        } else if (!event.detail.id) {
            this.containerTarget.classList.toggle("faq-question--open");
        }
    }
}
