// nav_plans_controller.js
import {Controller} from "@hotwired/stimulus";

export default class extends Controller {

    connect() {
        let url = window.location.href;
        this.element.querySelectorAll("a").forEach((a) => {
            var ahref = a.href.split("#")[0];
            if (url == ahref) {
                a.closest("li").classList.add("active");
            }
        });
    }
}
